import React, { useState } from 'react';

interface Props {
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login: React.FC<Props> = ({ setAuthenticated }) => {
  const [passwordInput, setPasswordInput] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    if (passwordInput === 'isakerbest') {
      setAuthenticated(true);
    } else {
      setError('The password you entered is not correct.');
    }
  };

  return (
    <main>
      <section className='login'>
        <div className='container'>
          <input
            type='password'
            value={passwordInput}
            onChange={(evt) => setPasswordInput(evt.target.value)}
            onKeyPress={(evt) => {
              if (evt.key === 'Enter') {
                handleLogin();
              }
            }}
          />
          <button onClick={() => handleLogin()}>Enter</button>
          {error !== '' && <p>{error}</p>}
        </div>
      </section>
    </main>
  );
};

export default Login;
