import { Link } from 'gatsby';
import React, { useState } from 'react';
import Layout from '../components/Layout';
import Login from '../components/Login';

const Landing = () => {
  const [authenticated, setAuthenticated] = useState(true);

  if (authenticated) {
    return (
      <Layout>
        <section className='landing'>
          <p>
            Hi, my name is Sarah, and I'm an Art Director based in Oslo.
            <br />
            <br />
            On this website you can find some of my <Link to='/work'>work</Link>
            .
          </p>
        </section>
      </Layout>
    );
  } else {
    return <Login setAuthenticated={setAuthenticated} />;
  }
};

export default Landing;
